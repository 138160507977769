import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function AboutPage(props) {
  return (
    <Layout>
      <Seo title="About Us" />
      <div class="contentBox">
        <h1>About Us</h1>
        <p>
          The Mars Group is developed from the combined experience of Mario
          Alvarez and Richard Sikes both, with diverse professional backgrounds
          yielding a robust and focused vision. Together, they operate five CPP
          specialized adult residential facilities and one community integration
          program for behaviorally challenged adults/clients. Mars Group was the
          first in California to successfully develop a certified delay egress
          and secure perimeter facility for clients that lack hazard awareness
          skills and poor impulse control.
        </p>
      </div>
    </Layout>
  )
}
